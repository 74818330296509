"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleInfo = exports.notImplemented = exports.handleSuccess = exports.handleDBError = exports.handleError = void 0;
const buefy_1 = require("buefy");
const buefy_2 = require("buefy");
function handleError(err, silent = false) {
    if (!err) {
        return;
    }
    console.error(err);
    const message = err.response?.data?.msg ?? err.msg ?? err.message ?? err.data ?? err;
    if (silent && !message) {
        return;
    }
    buefy_2.SnackbarProgrammatic.open({
        message,
        type: "is-danger",
        position: "is-top",
        duration: 10000
    });
}
exports.handleError = handleError;
function handleDBError(err) {
    console.error(err);
    let msg = "Unknown error. Try again later, or contact support.";
    if (!err) {
        msg = "No data was returned!";
    }
    if (err.message.includes("E11000")) {
        msg = err.message
            .slice(err.message.indexOf("dup key: {"))
            .replace("dup key: {", "");
        msg = `An account with this ${msg.slice(0, msg.indexOf(":"))} already exists!`;
    }
    buefy_1.ToastProgrammatic.open({
        message: msg,
        type: "is-danger"
    });
}
exports.handleDBError = handleDBError;
function handleSuccess(payload, useGenericMessage = false) {
    if (useGenericMessage) {
        buefy_1.ToastProgrammatic.open({
            message: "Success!",
            type: "is-success"
        });
        return;
    }
    buefy_1.ToastProgrammatic.open({
        message: payload.msg ?? payload.message ?? payload,
        type: "is-success"
    });
}
exports.handleSuccess = handleSuccess;
function notImplemented() {
    handleInfo("This feature is not implemented yet!");
}
exports.notImplemented = notImplemented;
function handleInfo(info) {
    console.log("Info:", info);
    buefy_1.ToastProgrammatic.open({
        message: info,
        type: "is-info"
    });
}
exports.handleInfo = handleInfo;
