<template>
  <div>
    <Nav />
    <div class="column">
      <nuxt />
    </div>
  </div>
</template>

<script>
import Nav from "@/components/layout/Nav";
export default {
  data() {
    return {};
  },
  components: {
    Nav
  }
};
</script>

<style>
nav {
  font-size: 20px;
}

.title-main {
  font-size: 4rem;
}

.p-1 {
  padding: 1em;
}
.m-1 {
  margin: 1em;
}

.p-2 {
  padding: 2em;
}
.m-2 {
  margin: 2em;
}
</style>
