<template>
  <b-navbar class="is-primary">
    <template slot="brand">
      <b-navbar-item id="brand" tag="router-link" :to="{ path: '/' }">
        HEFA
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Dashboard
      </b-navbar-item>
      <b-navbar-item tag="a" href="https://logs.hefa.lt/app/dashboards#/list">
        Metrics
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        @click="activeTab = 'messaging'"
        :to="{ path: `/messaging` }"
      >
        Messaging
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-item href="/api/user/logout">Logout</b-navbar-item>
    </template>
  </b-navbar>
</template>

<style>
#brand {
  font-weight: bold;
}
</style>

<script>
export default {
  data() {
    return { activeTab: "Dashboard" };
  },
  methods: {}
};
</script>
